import {VariantProps, cva} from 'class-variance-authority';
import { Search, X } from 'lucide-react';
import {MutableRefObject, forwardRef} from 'react';

import {cn} from '~/lib/utils';

const inputVariants = cva(
  'rounded-md border border-input bg-background px-3 py-2 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: '',
        search: 'pl-2 pr-7 m-0 border-2 focus:border-ice_blue',
        profile: 'rounded-lg',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
      icon?: React.ReactNode | boolean;
    }

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({className, type, variant, icon, ...props}, ref) => {
    const variants = inputVariants({variant, className});

    if(variant === 'search' || icon) {
      const searchIcon = <Search size={18} />;
      return (
        <div className="relative">
          <input type={type} className={cn(variants)} ref={ref} {...props} />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {typeof icon === 'boolean' ? (
              searchIcon
            ) : (
              <>{icon || searchIcon}</>
            )}
          </div>
        </div>
      );
    }

    return <input type={type} className={cn(variants)} ref={ref} {...props} />;
  },
);
Input.displayName = 'Input';

export {Input, inputVariants};
